import * as Sentry from '@sentry/react';
import { type OfflineModeManager } from 'OfflineMode';
import { type Config as FrontRoyalConfig, AppEnvType } from 'FrontRoyalConfig';
import { angularInjectorProvider } from 'Injector';
import {
    type SentryAttributes,
    type FrontRoyalTracingAttributes,
    type FrontRoyalSamplingContext,
} from './ErrorLogging.types';

export const GOTO_FRAME_TRACING_NAME = 'gotoFrame';
export const SHOW_FINISH_SCREEN_TRACING_NAME = 'showFinishScreen';
export const FLUSH_STORE_TRACING_NAME = 'flushStore';
export const WAIT_FOR_STATSIG_CLIENT_TRACING_NAME = 'waitForStatsigClient';
export const WAIT_FOR_STATSIG_LOADED_CLIENT_TRACING_NAME = 'waitForHopefullyLoadedClient';
export const LOAD_STATSIG_CLIENT_TRACING_NAME = 'loadStatsigClient';

// The name passed to startSentryInactiveSpan or startSentrySpan can be either
// one of the keys in this object, or it can be prefixed with the key and a colon.
// For example, both 'gotoFrame' and 'waitForStatsigClient:some_gate' are valid names.
const samplingRates: Record<string, number> = {
    [GOTO_FRAME_TRACING_NAME]: 0.001,
    [FLUSH_STORE_TRACING_NAME]: 0.0001,
    [WAIT_FOR_STATSIG_CLIENT_TRACING_NAME]: 0.001,
    [WAIT_FOR_STATSIG_LOADED_CLIENT_TRACING_NAME]: 0.001,
    [LOAD_STATSIG_CLIENT_TRACING_NAME]: 0.01,
};

/*
    This is a wrapper around Sentry.startSpan that adds some default attributes to the span.

    Note that, by default, 1% of transactions are sampled. If you want to sample a smaller amount
    for the transaction you're adding, update tracesSampler below
*/
export function startSentrySpan(name: string, attributes: SentryAttributes = {}) {
    const spanWrapper = (cb: (span: Sentry.Span) => void) =>
        Sentry.startSpan(
            {
                name,
                attributes: {
                    ...tracingAttributes(),
                    ...attributes,
                },
            },
            cb,
        );

    return spanWrapper;
}

export function startSentryInactiveSpan(name: string, attributes: SentryAttributes = {}) {
    return Sentry.startInactiveSpan({
        name,
        attributes: {
            ...tracingAttributes(),
            ...attributes,
        },
    });
}

export function tracesSampler(samplingContext: FrontRoyalSamplingContext, frontRoyalConfig: FrontRoyalConfig) {
    let samplingRate: number | boolean | null = null;
    const name: string | undefined = samplingContext.name;

    const { cordova } = samplingContext.attributes || {};

    if (name && name in samplingRates) {
        samplingRate = samplingRates[name];
    } else {
        Object.entries(samplingRates).forEach(([key, rate]) => {
            if (name?.startsWith(`${key}:`)) {
                samplingRate = rate;
            }
        });
    }

    if (name === SHOW_FINISH_SCREEN_TRACING_NAME) {
        samplingRate = frontRoyalConfig.debugFrameNavPerf() && cordova ? true : 0.005;
    }

    if (frontRoyalConfig.appEnvType() === AppEnvType.development) {
        // Give a warning in development if we've forgotten to set the sampling rate for some
        // transaction ...
        if (samplingRate === null) throw new Error(`No sampling rate set for ${name || 'unnamed transaction'}`);

        // ... but the sampling rate in development is always 100%.
        return true;
    }

    // If we do let a transaction slip through to production without defining a sampling rate,
    // default to 0.01
    return samplingRate === null ? 0.01 : samplingRate;
}

function tracingAttributes(): FrontRoyalTracingAttributes {
    const offlineModeManager = angularInjectorProvider.safeTestGet<OfflineModeManager>('offlineModeManager');

    return {
        cordova: !!window.CORDOVA,
        in_offline_mode: offlineModeManager?.inOfflineMode || false,
    };
}
