import { type EventLogger } from './EventLogger.types';

export const EventLoggerProvider = {
    logger: null as EventLogger | null,
    get() {
        return this.logger;
    },

    require() {
        if (!this.logger) {
            throw new Error('EventLoggerProvider is not initialized');
        }
        return this.logger;
    },

    set(val: EventLogger | null) {
        this.logger = val;
    },
};

export default EventLoggerProvider;
