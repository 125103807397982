import Auid from 'Auid';
import { ensurePageLoadId, unsetPageLoadId, BROWSER_AND_BUILD_INFO, processForThirdParties } from 'TinyEventLogger';
import { OnetrustCookieHelper } from 'Onetrust';
import { getDirectiveForEvents, REACT_ROUTER_DIRECTIVE_NAME } from 'NavigationHelpers';
import { debounce } from 'lodash/fp';
import angularModule from './event_logger_module';

/*

	EventLogger.log(type, obj);

*/

angularModule.factory('EventLogger', [
    '$injector',
    $injector => {
        const SuperModel = $injector.get('SuperModel');
        const EventBundle = $injector.get('EventLogger.EventBundle');
        const Event = $injector.get('EventLogger.Event');
        const $timeout = $injector.get('$timeout');
        const $window = $injector.get('$window');
        const $location = $injector.get('$location');
        const $rootScope = $injector.get('$rootScope');
        const PiggybackOnNg = $injector.get('PiggybackOnNg');
        const Singleton = $injector.get('Singleton');
        const $q = $injector.get('$q');
        const ConfigFactory = $injector.get('ConfigFactory');
        let $route; // see below in initialize

        const EventLogger = SuperModel.subclass(function factory() {
            this.include(Singleton);

            this.defineSingletonProperty(
                'trackLocationChanges',
                'destroy',
                'log',
                'trackAllNgEvents',
                'trackFirstViewRender',
                'setErrorHandler',
                'logStartEvent',
                'trackFocusAndBlur',
                'setLabelProperty',
                'tryToClearBuffer',
                'tryToSaveBuffer',
                'setClientConfig',
                'sendEventsImmediately',
                'onUserChange',
                'trackWindowFocusAndBlur',
            );

            this.createInstance = () => new EventLogger(10 * 1000);

            Object.defineProperty(this, 'disabled', {
                get() {
                    return this.instance._disabled;
                },
                set(value) {
                    this.instance._disabled = value;
                    if (this.instance._disabled && this.instance._clearBufferTimeout) {
                        $timeout.cancel(this.instance._clearBufferTimeout);
                    } else if (!this.instance._disabled) {
                        this.instance._clearBufferAndScheduleNextClear();
                    }
                },
            });

            return {
                initialize(bufferTime) {
                    // this is silly, but injecting route whenever EventLogger is
                    // exists can break totally unrelated tests, so we only inject
                    // it if EventLogger is actually used
                    $route = $injector.get('$route');

                    this.disabled = false;
                    this.bufferTime = bufferTime;
                    this.buffer = [];
                    this.listenerCancelers = [];
                    this.labelProperties = {};

                    this._clearBufferAndScheduleNextClear();

                    $($window).on('beforeunload.EventLogger', this._onUnload.bind(this));
                    this.listenerCancelers.push(() => {
                        $($window).off('beforeunload.EventLogger');
                    });
                },

                sendEventsImmediately() {
                    this._sendEventsImmediately = true;
                    this._tryToSaveBuffer();
                },

                setClientConfig(clientConfig) {
                    /*
                    Within front-royal, we get this from ClientConfig.
                    On marketing pages, we hardcode it in events.js
                        {
                            identifier: ...,
                            versionNumber: ...
                        }
                    */
                    this._clientConfig = clientConfig;
                },

                logStartEvent() {
                    this._sessionStartEvent = this.log(
                        'page_load:load',
                        {
                            ...BROWSER_AND_BUILD_INFO,
                            ...$window.cf_geo,
                            // track how much time it takes to load the app up to this point
                            value: window.performance.now(),
                            params: {
                                reload: !!window.performance
                                    .getEntriesByType?.('navigation')
                                    .map(nav => nav.type)
                                    .includes('reload'),
                            },
                        },
                        { segmentio: false },
                    );
                },

                onUserChange(auid) {
                    this._logUnloadEvent();

                    // We might be changing users but not actually
                    // loading up a new page. In that case, we need a new
                    // page load id.
                    unsetPageLoadId();

                    const promise = this._tryToSaveBuffer(true, { force_auid: auid });
                    this.logStartEvent();
                    return promise;
                },

                /*
                    For some events, we want to map a particular property (like
                    lesson_title) as the 'label' for the event. Label is a special
                    property in Google Analytics that helps define events.  We could
                    simply set this every time we log an event, but because of the way
                    we build up the event payload in various places, it is
                    convenient sometimes to set this globally
                */
                setLabelProperty(prop, eventTypeOrTypes) {
                    let eventTypes;
                    if (_.isArray(eventTypeOrTypes)) {
                        eventTypes = eventTypeOrTypes;
                    } else {
                        eventTypes = [eventTypeOrTypes];
                    }

                    eventTypes.forEach(eventType => {
                        this.labelProperties[eventType] = prop;
                    });
                },

                log(type, payload, options = {}) {
                    // buffer flushing shouldn't be occurring, but don't even bother if disabled
                    if (this.disabled) return null;

                    const event = this._getEvent(type, payload);

                    // HACK: The Iguana Event model normalizes around `event.properties` (see `initialize` and `asJson`).
                    // But a vanilla event object, created with `initializeEvent`, does not. Once we get rid of
                    // the Iguana Event model, that inconsistency will go away. But for the time being, we send `event.properties`
                    // as the `event` argument to `processForThirdParties`.
                    event.properties = processForThirdParties(event.properties, options);

                    this.buffer.push(event);

                    /*
                        If every event in the bundle is set to deferIndefinitely,
                        then the bundle will not be sent.  The only place we use this now is
                        for the logging of api request information, in order to prevent
                        the creation of an infinite loop where the success of one request
                        logs events that then have to be logged in a subsequent request.
                    */
                    if (options.deferIndefinitely) {
                        event.deferIndefinitely = true;
                    }

                    if (this._sendEventsImmediately) {
                        this._tryToSaveBuffer();
                    }

                    return event;
                },

                trackLocationChanges() {
                    const angularRouteChangeListener = $rootScope.$on('$routeChangeSuccess', () =>
                        this._logRouteEnter('angular'),
                    );
                    // We need the timeout here to make sure that the current route changes
                    const reactRouteChangeListener = $rootScope.$on('reactRouteChangeSuccess', () =>
                        $timeout().then(() => this._logRouteEnter('react')),
                    );
                    this.listenerCancelers.push(angularRouteChangeListener);
                    this.listenerCancelers.push(reactRouteChangeListener);

                    // If we're starting on a react route, then useEmitRouteChangeSuccessOnReactRouteChange already
                    // emitted reactRouteChangeSuccess before the angular app was set up. Trigger _logRouteEnter once
                    // to log the first route change. If we're starting on an angular route, then this call will do
                    // nothing and the  `$routeChangeSuccess` will trigger _logRouteEnter.
                    this._logRouteEnter('react');
                },

                trackWindowFocusAndBlur() {
                    // Sometimes we get two focus events when the browser gets focused, so debounce
                    const focusHandling = debounce(1000, () => {
                        this.log('window:focus', {});
                    });
                    $(window).on('focus', focusHandling);

                    const blurHandling = debounce(1000, () => {
                        this.log('window:blur', {});
                    });
                    $(window).on('blur', blurHandling);

                    this.listenerCancelers.push(() => {
                        $(window).off('focus', focusHandling);
                        $(window).off('blur', blurHandling);
                    });
                },

                trackFirstViewRender() {
                    // android 4.1 has performance but not performance.now
                    if (!$window.performance || !$window.performance.now) {
                        return;
                    }
                    const cancelListener = $rootScope.$on('$viewContentLoaded', () => {
                        cancelListener();
                        this.log('first_view_rendered', {
                            // value is the time since the user
                            // first started loading the page. Using 'value' and 'label'
                            // for special handling in Google Analytics (label is set
                            // via setLabelProperty below)
                            value: $window.performance.now() / 1000,
                        });
                    });
                    this.listenerCancelers.push(cancelListener);
                },

                // commented out only because tests mysteriously broke
                // when we moved EventLogger into common.  As far as I know,
                // it still works.  After moving this, we started running into
                // trouble with focus events both here and in challenge_blank_dir (which
                // did not move).  Mysterious.
                // trackFocusAndBlur: function() {
                //     ['input', 'textarea', 'select'].forEach(function(nodeName) {
                //         var directive = $injector.get(nodeName + 'Directive')[0];
                //         var origCompile = directive.compile;

                //         directive.compile = function(element, attr) {
                //             var origResult = origCompile(element, attr);

                //             // textarea and input compiles return an object
                //             // with a 'pre' property.  select returns a function
                //             var origFunc = origResult.pre ? origResult.pre : origResult;
                //             var handler = function(scope, element, attr, ctrls) {

                //                 if (element.type !== 'button' &&
                //                     element.type !== 'submit') {

                //                     var callback = function onFocusAndBlur(event) {
                //                         EventLogger.log(event.type, {
                //                             elem: EventLogger.propertiesForElem(element)
                //                         }, {
                //                             segmentio: false // no need to log this to segmentio until we need it there
                //                         });
                //                     };

                //                     element.on('focus.event_logger', callback);
                //                     element.on('blur.event_logger', callback);

                //                     scope.$on('$destroy.event_logger', function() {
                //                         element.off('focus.event_logger', callback);
                //                         element.off('blur.event_logger', callback);
                //                     });
                //                 }
                //                 return origFunc(scope, element, attr, ctrls);
                //             };

                //             if (origResult.pre) {
                //                 return {
                //                     pre: handler
                //                 };
                //             } else {
                //                 return handler;
                //             }
                //         };
                //     });

                // },

                trackAllNgEvents(directiveName, auto) {
                    const self = this;
                    self.$$autoTrackDirectives = self.$$autoTrackDirectives || {};
                    if (auto === false) {
                        self.$$autoTrackDirectives[directiveName] = false;
                        return;
                    }
                    self.$$autoTrackDirectives[directiveName] = true;

                    PiggybackOnNg.on(directiveName, (scope, _element, attr, event) => {
                        // Ignore the app-main-container ng-click="void(0)" hack -- see app_shell.html
                        if (angular.isDefined(attr.noLog)) {
                            return;
                        }

                        let label = attr.label && scope.$eval(attr.label);
                        label = label || attr[directiveName] || attr[`noApply${event.type.camelize(true)}`];

                        // We removed the ability to log Ng events to third parties
                        // like segment and customer.io. I'm leaving this bit of code
                        // here out of paranoia, so we get notified if we missed anything.
                        // If we haven't seen one of these on Sentry after a month, this
                        // can be removed.
                        if (
                            attr.segmentio === '' ||
                            scope.$eval(attr.segmentio) ||
                            scope.$eval(attr.segmentioType) ||
                            scope.$eval(attr.segmentioLabel)
                        ) {
                            $injector
                                .get('ErrorLogService')
                                .notify(`Ng '${event.type}' event tracked with segmentio attrs.`);
                        }

                        // if auto-tracking has been turned off, don't log this event.
                        if (!self.$$autoTrackDirectives[directiveName]) {
                            return;
                        }

                        const evt = this.log(event.type, { label }, { segmentio: false });

                        // Track how long it takes us to
                        // handle each click (or whatever) so we
                        // can identify slow handlers.  Theoretically, since
                        // we're adding the performance information asynchronously,
                        // it could be added after the event has been sent, but that
                        // will be extremely rare and not really a problem..
                        const now = new Date();
                        evt.properties.performance = {};
                        $injector.get('$timeout')(
                            () => {
                                const elapsed = new Date() - now;
                                evt.properties.performance.toTimeout = elapsed;

                                // for debugging
                                $rootScope.lastEvent = `${directiveName}: ${elapsed}`;
                                if (elapsed > 100) {
                                    // console.warn('Poorly performing ' + directiveName + '. Took ' + elapsed + ' ms.', evt);
                                }
                            },
                            0,
                            false,
                        );
                        scope.$evalAsync(() => {
                            evt.properties.performance.toEvalAsync = new Date() - now;
                        });
                    });
                },

                destroy() {
                    this.buffer = [];
                    $timeout.cancel(this._clearBufferTimeout);
                    this.listenerCancelers.forEach(func => {
                        func();
                    });
                },

                tryToClearBuffer(lastChance, format) {
                    const events = this.buffer;

                    // Retry later if:
                    // 1. there are no events buffered
                    // 2. ConfigFactory is not yet initialized
                    if (events.length === 0 || !ConfigFactory.isInitialized()) {
                        return null;
                    }

                    // Retry later if:
                    // 1. All events are marked as deferIndefinitely
                    // 2. This was not kicked off by the onUnload listener
                    let undeferredEvent;

                    // eslint-disable-next-line no-restricted-syntax
                    for (const event of events) {
                        if (!event.deferIndefinitely) {
                            undeferredEvent = event;
                            break;
                        }
                    }

                    if (!undeferredEvent && !lastChance) {
                        return null;
                    }

                    // When we are using Segment China on the client, it'd be weird to log with server Segment because
                    // there is no China-specific setup on the server, so those events would go to the regular Segment.
                    const config = ConfigFactory.getSync();
                    if (config.usingSegmentChina()) {
                        events.forEach(e => {
                            delete e.properties.log_to_server_conversions_segment;
                        });
                    }

                    this.buffer = [];

                    const jsonnedEvents = events.map(event => event.asJson());

                    const eventBundle = EventBundle.new({ events: jsonnedEvents });

                    // store the original events so that if the request
                    // fails we can stick them back into the buffer
                    eventBundle.$$eventObjects = events;

                    return format === 'json' ? eventBundle.asJson() : eventBundle;
                },

                // In specs, we need to distinguish between someone caling the public
                // method and the private method being called internally
                tryToSaveBuffer(lastChance, meta = {}) {
                    return this._tryToSaveBuffer(lastChance, meta);
                },

                _tryToSaveBuffer(lastChance, meta = {}) {
                    const self = this;
                    const eventBundle = self.tryToClearBuffer(lastChance);

                    meta.consent_disabled_integrations = OnetrustCookieHelper.getDisabledIntegrationsObject();

                    // need to check disabled to prevent _onUnload from saving
                    if (eventBundle && !EventLogger.disabled) {
                        return eventBundle
                            .save(meta, { 'FrontRoyal.ApiErrorHandler': { skip: true } })
                            .catch(response => {
                                if (response.status === 0) {
                                    // add the events back to the beginning of the buffer so
                                    // they will be sent again in the next call
                                    self.buffer = eventBundle.$$eventObjects.concat(self.buffer);
                                    $injector.get('HttpQueue').unfreezeAfterError(response.config);
                                } else if (
                                    response.status === 406 &&
                                    response.data &&
                                    !!response.data.user_from_auid_deleted
                                ) {
                                    // If the server is telling us that it doesn't have a current user,
                                    // and the user referenced by the AUID in the headers/cookies of the
                                    // request has been deleted, we should regenerate an AUID so we aren't
                                    // logging events for a deleted user.
                                    // This also ensures that, should the end-user reregister, the newly-
                                    // registered user won't end up with the same id as their old user.
                                    Auid.reset();
                                    $injector.get('HttpQueue').unfreezeAfterError(response.config);
                                } else if (response.config) {
                                    // do default handling

                                    response.config['FrontRoyal.ApiErrorHandler'] = { skip: false };

                                    // may not be loaded yet, but use if available
                                    if ($injector.has('ApiErrorHandler')) {
                                        $injector.get('ApiErrorHandler').onResponseError(response);
                                    }
                                } else {
                                    throw response;
                                }
                            });
                    }

                    // eslint-disable-next-line no-else-return
                    else {
                        return $q.when();
                    }
                },

                /**
                 * @deprecated
                 * @todo At some point we'll switch to `TinyEventLogger/initializeEvent` instead
                 */
                _getEvent(type, obj) {
                    const props = {};

                    // NOTE: ensurePageLoad could have been called from outside of EventLogger
                    // if we are on the dynamic_landing_page.  Since ensurePageLoadId stores
                    // the page load on the window under the hood, we will get the same one here.
                    angular.extend(props, obj);
                    props.page_load_id = ensurePageLoadId();
                    const event = new Event(type, props);

                    const labelProp = this.labelProperties[type];
                    if (event.properties[labelProp]) {
                        event.properties.label = event.properties[labelProp];
                    }
                    return event;
                },

                _logRouteEnter(supportedRouteType) {
                    // We've set up two listeners that trigger this method, one from angular and one from react. When
                    // navigating between routes in angular or in react, this method is only triggered once. When navigating
                    // between react and angular, this method is triggered twice. In those cases, we only want to log once,
                    // so filter one out.
                    const routeType =
                        $route?.current?.$$route?.directive === REACT_ROUTER_DIRECTIVE_NAME ? 'react' : 'angular';
                    if (routeType !== supportedRouteType) return;

                    const props = {
                        // directive is added in the Event initializer
                        params: $route.current.params,
                        path: $location.url(),
                    };

                    const name = getDirectiveForEvents($route, $location) || $location.path();

                    let url;
                    // in CORDOVA, location.href is a long thing starting with file://
                    if ($window.CORDOVA) {
                        url = `Smartly.app${$window.location.href.split('index.html#')[1] || '/'}`;
                    } else {
                        url = $window.location.href;
                    }

                    this._logThirdPartyPageCall(name, { url, path: $location.path() });

                    // and record the event
                    this.log('route_change:enter', props, {
                        segmentio: false,
                    });
                },

                _logThirdPartyPageCall(title, properties) {
                    window.analytics?.page(undefined, title, { ...properties, title });
                },

                _clearBufferAndScheduleNextClear() {
                    this._tryToSaveBuffer(false);
                    this._clearBufferTimeout = $timeout(
                        this._clearBufferAndScheduleNextClear.bind(this),
                        this.bufferTime,
                        false,
                    );
                },

                _logUnloadEvent() {
                    this._currentRoute = undefined; // in onUserChange, prevent the leave event from being logged for the new user
                    if (this._sessionStartEvent) {
                        this.log(
                            // NOTE: we've seen beforeunload get triggered and then users continue
                            // to go on with their browser session.  It actualluy doesn't even
                            // seem to be particularly rare.  So even though this event says
                            // page_load:unload, it doesn't necessarily mean that the session is ending.
                            'page_load:unload',
                            {},
                            { segmentio: false },
                        ).addDurationInfo(this._sessionStartEvent);
                        this._sessionStartEvent = undefined;
                    }
                },

                _onUnload() {
                    this._logUnloadEvent();
                    try {
                        this._tryToSaveBuffer(true);
                    } catch (e) {
                        // ignore message that happens when auto-reloading tests
                        if (!e.message.match(/Unexpected call to event_bundles.create/)) {
                            throw e;
                        }
                    }
                },
            };
        });

        EventLogger.setLabelProperty('first_view_rendered', 'directive');
        return EventLogger;
    },
]);
