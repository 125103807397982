type Extra = Record<string, string>;

/*
    LoggableError is just a wrapper around Error that allows for adding an `extra` property.
    If this error is logged by ErrorLogService#notify, the extra properties will be added to the
    error report sent to Sentry.
*/
export class LoggableError extends Error {
    public readonly extra: Extra;

    constructor(message: string, extra: Extra = {}) {
        super(message);
        this.name = 'LoggableError';
        this.extra = extra;
        if (Error.captureStackTrace) Error.captureStackTrace(this, LoggableError);
    }
}
