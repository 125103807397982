import { StudentNetworkEvent, type CamelCasedStudentNetworkEvent } from 'StudentNetworkEvent';
import { useState } from 'react';
import { Modal } from 'Modal';
import { useTranslation } from 'react-i18next';
import { EventListEntry } from './EventListEntry';

type Props = {
    events: CamelCasedStudentNetworkEvent[];
    handleEventSelect: (event: CamelCasedStudentNetworkEvent) => void;
};

export function ExpandableEventGroup({ events, handleEventSelect }: Props) {
    const { t } = useTranslation('back_royal', { keyPrefix: 'lessons.stream.featured_events' });
    const [showModal, setShowModal] = useState(false);

    return (
        <div>
            <StudentNetworkEvent isMultipleEvents event={events[0]} onClick={() => setShowModal(true)} />
            {showModal && (
                <Modal title={t('multiple_times')!} onClose={() => setShowModal(false)}>
                    {events.map(event => (
                        <EventListEntry key={event.id}>
                            <StudentNetworkEvent event={event} onClick={() => handleEventSelect(event)} />
                        </EventListEntry>
                    ))}
                </Modal>
            )}
        </div>
    );
}
