import { groupBy, orderBy } from 'lodash/fp';
import moment from 'moment';
import { useMemo } from 'react';
import { studentNetworkApi } from 'StudentNetwork/StudentNetworkApi';
import { type CamelCasedStudentNetworkEvent } from 'StudentNetworkEvent';

const { useGetStudentNetworkEventsQuery } = studentNetworkApi;
const MAX_LISTINGS = 4;

const isRecommendedEventInNextTwoWeeks = (event: CamelCasedStudentNetworkEvent) =>
    event.recommended && moment(event.startTime * 1000).isSameOrBefore(moment().add(14, 'days'));

export function useEventsForEventList({ featured }: { featured: boolean }) {
    // Data is returned from the API sorted by start time ascending
    const { data = [], isLoading } = useGetStudentNetworkEventsQuery({ featured });

    const filteredData = useMemo(() => {
        if (featured) return data;

        return data.filter(isRecommendedEventInNextTwoWeeks);
    }, [data, featured]);

    const eventsByTitle = useMemo(() => groupBy<CamelCasedStudentNetworkEvent>('title')(filteredData), [filteredData]);

    const orderedEventTitles = useMemo(
        () =>
            orderBy<string>(eventTitle => eventsByTitle[eventTitle as string][0].startTime)('asc')(
                Object.keys(eventsByTitle),
            ).slice(0, MAX_LISTINGS),
        [eventsByTitle],
    );

    return {
        orderedEventTitles,
        eventsByTitle,
        isLoading,
    };
}
