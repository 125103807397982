import {
    type SharedSelection,
    DropdownItem,
    DropdownMenu,
    Dropdown as HeroUiDropdown,
    DropdownTrigger,
} from '@heroui/react';
import React, { useCallback, type ComponentProps } from 'react';
import { twMerge } from 'tailwind-merge';

type Props = {
    children: React.ReactNode;
    selectedKeys: string[];
    selectionMode: ComponentProps<typeof DropdownMenu>['selectionMode'];
    items: (ComponentProps<typeof DropdownItem> & { label: React.ReactNode })[];
    classNames?: {
        dropdown?: ComponentProps<typeof HeroUiDropdown>['classNames'];
        trigger?: ComponentProps<typeof DropdownTrigger>['classNames'];
        menu?: ComponentProps<typeof DropdownMenu>['classNames'];
    };
    onSelectionChange: (selectedKeys: string[]) => void;
    isOpen?: boolean;
    onOpenChange?: (isOpen: boolean) => void;
    placement?: ComponentProps<typeof HeroUiDropdown>['placement'];
    closeOnSelect?: boolean;
    showArrow?: boolean;
};

export function Dropdown({
    children,
    items,
    classNames,
    selectedKeys,
    selectionMode,
    onSelectionChange,
    isOpen,
    onOpenChange,
    placement = 'bottom-start',
    closeOnSelect,
    showArrow = false,
}: Props) {
    const handleSelectionChange = useCallback(
        (selection: SharedSelection) => {
            const selected = Array.from(selection);
            onSelectionChange(selected as string[]);
        },
        [onSelectionChange],
    );

    return (
        <HeroUiDropdown
            classNames={{
                base: classNames?.dropdown?.base,
                content: twMerge(
                    'border border-solid border-slate-grey-200 shadow-smallish',
                    classNames?.dropdown?.content,
                ),
                trigger: classNames?.dropdown?.trigger,
                backdrop: classNames?.dropdown?.backdrop,
                arrow: classNames?.dropdown?.arrow,
            }}
            placement={placement}
            isOpen={isOpen}
            onOpenChange={onOpenChange}
            closeOnSelect={closeOnSelect}
            showArrow={showArrow}
        >
            <DropdownTrigger
                classNames={{
                    base: classNames?.trigger?.base,
                    icon: classNames?.trigger?.icon,
                }}
            >
                {children}
            </DropdownTrigger>
            <DropdownMenu
                classNames={{
                    list: twMerge('p-0 m-0', classNames?.menu?.list),
                    base: classNames?.menu?.base,
                    emptyContent: classNames?.menu?.emptyContent,
                }}
                selectedKeys={selectedKeys}
                selectionMode={selectionMode}
                onSelectionChange={handleSelectionChange}
            >
                {items.map(({ label, classNames: itemClassNames, ...itemProps }) => (
                    <DropdownItem
                        {...itemProps}
                        classNames={{
                            base: twMerge(
                                'text-black text-base',
                                'data-[hover=true]:bg-slate-grey-100 data-[focus=true]:bg-slate-grey-100 data-[focus=true]:outline-none',
                                'data-[selectable=true]:hover:bg-slate-grey-100 data-[selectable=true]:focus:bg-slate-grey-100',
                                itemClassNames?.base,
                            ),
                            description: itemClassNames?.description,
                            title: itemClassNames?.title,
                            wrapper: itemClassNames?.wrapper,
                            shortcut: itemClassNames?.shortcut,
                            selectedIcon: twMerge('relative -top-[2px] *:*:duration-120', itemClassNames?.selectedIcon),
                        }}
                    >
                        {label}
                    </DropdownItem>
                ))}
            </DropdownMenu>
        </HeroUiDropdown>
    );
}
