import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';
import { StudentNetworkEvent, type CamelCasedStudentNetworkEvent } from 'StudentNetworkEvent';
import { useEventLogger } from 'FrontRoyalAngular/useEventLogger';
import { navigationHelper } from 'NavigationHelpers';
import FrontRoyalSpinner from 'FrontRoyalSpinner';
import { Card } from 'Card';
import { Text } from 'Text';
import { BlockLink } from 'BlockLink';
import { useEventsForEventList } from './useEventsForEventList';
import { EventListEntry } from './EventListEntry';
import { ExpandableEventGroup } from './ExpandableEventGroup';

type Props = {
    featured?: boolean;
};

type PresentationProps = {
    orderedEventTitles: string[];
    eventsByTitle: Record<string, CamelCasedStudentNetworkEvent[]>;
    featured: boolean;
    handleViewAllPress: () => void;
    handleEventSelect: (networkEvent: CamelCasedStudentNetworkEvent) => void;
    isLoading: boolean;
};

export function EventList({ featured = false }: Props) {
    const EventLogger = useEventLogger();
    const { loadRoute } = navigationHelper();
    const { orderedEventTitles, eventsByTitle, isLoading } = useEventsForEventList({ featured });

    const logAndNavigateToEvents = useCallback(
        (networkEvent?: CamelCasedStudentNetworkEvent) => {
            EventLogger.log('student-dashboard:navigate-to-student-network', {
                student_network_event_id: networkEvent?.id,
            });

            loadRoute(
                `/student-network?${
                    networkEvent?.id ? `event-id=${networkEvent.id}` : featured ? 'show-featured-events' : 'show-events'
                }`,
            );
        },
        [featured, EventLogger, loadRoute],
    );

    const handleEventSelect = useCallback(
        (networkEvent: CamelCasedStudentNetworkEvent) => {
            logAndNavigateToEvents(networkEvent);
        },
        [logAndNavigateToEvents],
    );

    return (
        <EventListPresentation
            orderedEventTitles={orderedEventTitles}
            eventsByTitle={eventsByTitle}
            featured={featured}
            handleViewAllPress={logAndNavigateToEvents}
            handleEventSelect={handleEventSelect}
            isLoading={isLoading}
        />
    );
}

export function EventListPresentation({
    orderedEventTitles,
    eventsByTitle,
    featured,
    handleViewAllPress,
    handleEventSelect,
    isLoading,
}: PresentationProps) {
    const { t } = useTranslation('back_royal', { keyPrefix: 'lessons.stream.featured_events' });
    const headingText = featured ? t('featured_events') : t('upcoming_events');

    return (
        <div>
            <div className="flex items-center justify-between px-3 pb-5">
                <Text as="h2" weight="semibold" color="black" uppercase>
                    {headingText}
                </Text>
                <BlockLink
                    className="text-base"
                    color="slate-grey"
                    anchorIcon="right-arrow"
                    onPress={handleViewAllPress}
                >
                    {t('view_all')}
                </BlockLink>
            </div>

            <Card classNames={{ body: 'p-0' }}>
                {isLoading && <FrontRoyalSpinner className="my-8" />}
                {!isLoading &&
                    orderedEventTitles.map(eventTitle => {
                        const events = eventsByTitle[eventTitle];
                        const isSingleEvent = events.length === 1;

                        return (
                            <EventListEntry key={events[0].id}>
                                {isSingleEvent ? (
                                    <StudentNetworkEvent
                                        event={events[0]}
                                        onClick={() => handleEventSelect(events[0])}
                                    />
                                ) : (
                                    <ExpandableEventGroup events={events} handleEventSelect={handleEventSelect} />
                                )}
                            </EventListEntry>
                        );
                    })}
            </Card>
        </div>
    );
}
