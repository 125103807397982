import angularModule from './devise_token_auth_client_module';

angularModule.factory('DeviseClient', [
    '$injector',
    $injector => {
        const $http = $injector.get('$http');
        const ngDeviseTokenAuthClient = $injector.get('ngDeviseTokenAuthClient');
        const ConfigFactory = $injector.get('ConfigFactory');

        const API_ROOT = `${window.ENDPOINT_ROOT}/users`;

        function checkConfig(deviseFn, dtaFn) {
            return ConfigFactory.getConfig().then(config => {
                if (config.disableDevise()) return dtaFn();
                return deviseFn();
            });
        }

        return {
            showSelf: () =>
                checkConfig(
                    () => $http.get(`${API_ROOT}/show_self`),
                    () => ngDeviseTokenAuthClient.validateUser(),
                ),
            signIn: params =>
                checkConfig(
                    () => $http.post(`${API_ROOT}/sign_in`, { user: params }),
                    () => ngDeviseTokenAuthClient.submitLogin(params),
                ),
            signOut: () =>
                checkConfig(
                    () => $http.delete(`${API_ROOT}/sign_out`),
                    () => ngDeviseTokenAuthClient.signOut(),
                ),
            register: params =>
                checkConfig(
                    () => $http.post(API_ROOT, { user: params }),
                    () => ngDeviseTokenAuthClient.submitRegistration(params),
                ),
            requestResetPasswordToken: params =>
                checkConfig(
                    () => $http.post(`${API_ROOT}/password`, { user: params }),
                    () => ngDeviseTokenAuthClient.requestPasswordReset(params),
                ),
            updatePassword: params =>
                checkConfig(
                    () => $http.put(`${API_ROOT}/password`, { user: params }),
                    () => ngDeviseTokenAuthClient.updatePassword(params),
                ),
            omniauth: (provider, params, opts) =>
                checkConfig(
                    () => $http.get(`${API_ROOT}/auth/${provider}`, params),
                    () => ngDeviseTokenAuthClient.authenticate(provider, opts),
                ),
        };
    },
]);
